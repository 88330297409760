<template>
    <div>
        <nl-loader v-if="preloading" :show="true" />

        <div v-else>
            <b-loading v-model="isLoading" :is-full-page="true" />

            <section class="box min-vh-100">
                <div class="mb-2">
                    <a href="/admin/campaigns" class="button is-light is-small text-decoration-none">
                        <i class="fas fa-backward mr-1" />
                        Retour aux accompagnements
                    </a>
                </div>

                <section class="form">
                    <form>
                        <div class="form-row justify-content-center">
                            <div class="col-12 col-md-8 mb-3">
                                <label class="label">Utilisateur <span class="text-danger">*</span></label>
                                <b-autocomplete
                                    v-model="emailChoosen"
                                    :data="users"
                                    placeholder="Nom / Prénom / Adresse email..."
                                    :loading="isFetching"
                                    :clearable="true"
                                    :open-on-focus="true"
                                    field="email"
                                    @typing="getAsyncUsers"
                                    @select="selectUser"
                                >
                                    <!-- <template #empty>Aucun utilisateur trouvé</template> -->

                                    <template slot-scope="props">
                                        <p>
                                            {{
                                                `${props.option.firstname} ${props.option.lastname} (${props.option.email})`
                                            }}
                                        </p>
                                    </template>
                                </b-autocomplete>
                            </div>

                            <div class="col-12 col-md-8 mb-3">
                                <div class="form-group">
                                    <label class="label">Site <span class="text-danger">*</span></label>
                                    <input
                                        id="url"
                                        v-model="form.url"
                                        type="text"
                                        name="url"
                                        class="form-control"
                                        required
                                    />
                                </div>
                            </div>

                            <div class="col-12 col-md-8 mb-3">
                                <div class="form-group">
                                    <label class="label">Catégorie du Site <span class="text-danger">*</span></label>
                                    <select v-model="form.groupe_id" class="form-control" required>
                                        <option :value="null" selected>-</option>
                                        <option v-for="group in groups" :key="group.id" :value="group.id">
                                            <div v-if="group.parent_id">
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ group.name }}
                                            </div>

                                            <div v-else>
                                                {{ group.name }}
                                            </div>
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-12 col-md-8 mb-3 form-row p-0">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label class="label"
                                            >Durée de l'accompagnement <span class="text-danger">*</span></label
                                        >
                                        <select v-model="form.duree" class="form-control">
                                            <option :value="null" selected>-</option>
                                            <option
                                                v-for="(duration, index) in durations"
                                                :key="index"
                                                :value="duration"
                                            >
                                                {{
                                                    duration === 12
                                                        ? '1 an'
                                                        : duration === 24
                                                        ? '2 ans'
                                                        : `${duration} mois`
                                                }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label class="label">Budget mensuel <span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <input
                                                id="budget_mensuel"
                                                v-model="form.budget_mensuel"
                                                type="text"
                                                name="budget_mensuel"
                                                class="form-control"
                                                required=""
                                            />
                                            <div class="input-group-append">
                                                <span class="input-group-text">€</span>
                                            </div>
                                        </div>
                                        <p v-if="errors.hasOwnProperty('budget_mensuel')" class="small text-danger">
                                            {{ errors.budget_mensuel.join(' ') }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-8 mb-3 form-row p-0">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label class="label">Consultant OffSite</label>
                                        <select v-model="form.accompagnant_id" class="form-control">
                                            <option :value="null" selected>-</option>
                                            <option
                                                v-for="(advisor, index) in advisors.offsite"
                                                :key="index"
                                                :value="advisor.id"
                                            >
                                                {{ `${advisor.firstname} ${advisor.lastname} (${advisor.email})` }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label class="label">Consultant OnSite</label>
                                        <select v-model="form.accompagnant2_id" class="form-control">
                                            <option :value="null" selected>-</option>
                                            <option
                                                v-for="(advisor, index) in advisors.onsite"
                                                :key="index"
                                                :value="advisor.id"
                                            >
                                                {{ `${advisor.firstname} ${advisor.lastname} (${advisor.email})` }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-8 mb-3">
                                <div class="form-group">
                                    <label class="label">Brief Client</label>
                                    <textarea v-model="form.brief_client" class="form-control" />
                                </div>
                            </div>

                            <div class="col-12 col-md-8 mb-3">
                                <div class="form-group">
                                    <label class="label">Brief OnSite</label>
                                    <textarea v-model="form.brief_onsite" class="form-control" />
                                </div>
                            </div>

                            <div v-if="form.url" class="col-12 col-md-8 mb-3">
                                <NewCampaignUrl ref="newKeywords" :url="form.url" />
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="col-12 col-md-8 row justify-content-end mx-auto mt-4">
                                <button class="button is-warning ml-auto" @click.prevent="newAccompagnement">
                                    Créer un nouvel accompagnement
                                </button>
                            </div>
                        </div>
                    </form>
                </section>
            </section>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce'

    export default {
        name: 'AccompagnementNew',
        title: 'mes-accompagnements.ask',
        data() {
            return {
                errors: [],
                groups: [],
                advisors: [],
                durations: [],
                users: [],
                userSelected: null,
                emailChoosen: '',
                form: {
                    duree: null,
                    accompagnant_id: null,
                    accompagnant2_id: null,
                    groupe_id: 1
                },

                isLoading: false,
                preloading: false,
                isFetching: false
            }
        },

        created() {
            this.getData()
        },

        methods: {
            getData: function () {
                this.preloading = true

                var groups = this.axios
                    .get(`/admin/accompagnements/create`)
                    .then(response => {
                        this.groups = response.data.groups
                        this.advisors = response.data.advisors
                        this.durations = response.data.durations
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })

                Promise.all([groups]).then(() => {
                    this.preloading = false
                })
            },

            getAsyncUsers: debounce(function (name) {
                if (!name.length && name.length < 3) {
                    this.users = []
                    return
                }

                this.isFetching = true

                this.axios
                    .get(`/admin/users/search?q=${name}`)
                    .then(response => {
                        this.users = response.data.users
                    })
                    .catch(error => {
                        this.snackNotif('Une erreur est survenue', 'is-danger')
                        console.error(error)
                    })
                    .finally(() => (this.isFetching = false))
            }, 500),

            selectUser(option) {
                this.userSelected = option
                this.emailChoosen = option.email
            },

            newAccompagnement() {
                this.errors = []

                if (!this.userSelected || !this.userSelected.hasOwnProperty('id')) {
                    this.snackNotif('Utilisateur invalide')
                    return
                }

                this.isLoading = true

                let formData = new FormData()

                for (const [key, value] of Object.entries(this.form)) {
                    if (value == null) {
                        formData.append(key, '')
                    } else {
                        formData.append(key, value)
                    }
                }

                formData.append('keywords', JSON.stringify(this.$refs.newKeywords.checkedRows))
                formData.append('user_id', JSON.stringify(this.userSelected.id))

                this.axios
                    .post(`/admin/accompagnements`, formData)
                    .then(response => {
                        console.log(response)

                        this.snackNotif('Accompagnement créé avec succès !')
                        window.location.href = '/admin/campaigns'

                        this.resetForm()
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            this.snackNotif('Formulaire incorrect', 'is-danger')
                            this.errors = error.response.data.errors
                        } else {
                            this.snackNotif(error.response.data.message, 'is-danger')
                        }

                        console.error(error)
                    })
                    .finally(() => (this.isLoading = false))
            },

            newKeyword() {
                this.form.keywords.push({})
            },

            loadRefDomains() {
                this.loadRefDomains = true
            },

            resetForm() {
                this.users = []
                this.userSelected = null
                this.emailChoosen = ''
                this.form = {
                    duree: null,
                    accompagnant_id: null,
                    accompagnant2_id: null,
                    groupe_id: null
                }
            }
        }
    }
</script>
